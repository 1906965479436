/**
 * Check that two enum type have exactly the same keys.
 *
 * This function does nothing, but it will cause an error during compilation if
 * the keys don't match.
 */
export const _enforceSameEnumKeys = <E1 extends Record<keyof E2, unknown>, E2 extends Record<keyof E1, unknown>>(
  e1: E1,
  e2: E2,
): void => {};
