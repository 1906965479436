// Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.
import { Client } from '../client/client.model';
import { RiskRating } from '../company-record/company-record.model';
import { HasId } from '../core/entity/entity.service';
import { Country } from '../country/country.model';
import { Currency } from '../currency/currency.model';
import { Format, TransactionType } from '../dataset/dataset.model';
import { Industry } from '../industry/industry.model';

// eslint-disable-next-line @typescript-eslint/no-unused-vars -- needed for docstring reference
import { getEnumKeyFromValue } from '../shared/util';
import { _enforceSameEnumKeys } from '../shared/util.tooling';

export interface Assessment extends HasId {
  client: Client;

  name: string;
  created: Date;

  mode: keyof typeof Mode;
  version: number;

  type: keyof typeof TransactionType;

  format: keyof typeof Format;

  notification: string;

  published: boolean;

  startDate: Date | string;
  endDate: Date | string;
  datasetId: number;
  legacy: boolean;
  currency?: Currency;
}

/**
 * Footprint (assessment) modules. These correspond to MRIO satellite blocks.
 */
// TODO: Rename to Module or similar to match domain language.
export enum EMode {
  // Order is important here.
  SLAVES = 'SLAVES',
  GHG_EMISSIONS = 'GHG_EMISSIONS',
  BIODIVERSITY = 'BIODIVERSITY',
  LAND = 'LAND',
  WATER = 'WATER',
  TIVA = 'TIVA',
}

/**
 * @deprecated Use {@link EMode} instead: its keys are the same as its values,
 * so using it is much easier: no need to use {@link getEnumKeyFromValue}, etc.
 *
 * And it's better for i18n to use a separate mapping from that to user-facing
 * text.
 */
export enum Mode {
  // Order is important here.
  SLAVES = 'Modern Slavery',
  GHG_EMISSIONS = 'Emissions',
  BIODIVERSITY = 'Biodiversity',
  LAND = 'Land Use',
  WATER = 'Water',
  TIVA = 'Trade in Value Added',
}

_enforceSameEnumKeys(EMode, Mode);

export enum EntityType {
  ASSESSMENT = 'assessment',
  ASSESSMENT_ITEM = 'assessment item',
  RECORD = 'record',
  RECORDS = 'records',
}

export type WithGroupByFields<G extends string[]> = {
  [K in G[number]]: K extends 'quarter' ? string : number;
};
export type WithMetrics = {
  footprint: number;
  intensity: number;
  breakdown: number;
};
export type WithWeight = {
  /** The weight of the transactions used to calculate the footprint. Only
   * useful when grouping by transaction_id. */
  weight: number;
};

export type Footprint<G extends string[] = [], F extends boolean = true> = {
  id: number;
  amount_usd: number;
  amount: number;
  risk: keyof typeof RiskRating;
} & WithGroupByFields<G> &
  // Select fields based on whether footprint metrics are requested
  (F extends true ? WithMetrics : WithWeight);

export type CountryFootprint = Footprint<['country_id']>;
export type IndustryFootprint = Footprint<['industry_id']>;
export type ItemFootprint = Footprint<['item_id']>;
export type RecordFootprint = Footprint<['record_id']>;
export type AssessmentFootprint = Footprint<['assessment_id']>;
export type SectorFootprint = Footprint<['sector_id', 'country_id', 'industry_id']>;
export type TransactionSectorFootprint = Footprint<['transaction_id', 'sector_id', 'country_id', 'industry_id'], false>;
export type TierFootprint = Footprint<['tier']>;
export type QuarterlyFootprint = Footprint<['quarter']>;

export interface EmissionFootprint {
  scope: {
    1: number;
    2: number;
    3: {
      up: number;
      down: number;
    };
  };
  tcfd: TCFDReport[];
}

export interface TCFDReport {
  theme: string;
  category: string;
  value: number;
}

export interface AssessmentByIndustryData {
  /**
   * Country's 3 letter code (ISO 3166-1 alpha-3)
   */
  country?: string;
  industry: string;
  value: number;
  percentage: number;
}

export interface AssessmentByCountryData {
  /**
   * Country's 3 letter code (ISO 3166-1 alpha-3)
   */
  label: string;
  name: string;
  value: number;
  percentage: number;
}

export interface AssessmentBySectorData {
  /**
   * Country's 3 letter code (ISO 3166-1 alpha-3)
   */
  sector_id: number;
  country: Country;
  industry: Industry;
  value: number;
  percentage: number;
}
